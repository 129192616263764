import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { Toaster, toast } from "react-hot-toast";

const CustomerQuotation = () => {
  const deletstyle = { color: "red", fontSize: "1.5rem" };
  const [isTextBoxOpen, setIsTextBoxOpen] = useState(false);
  const [isTextBox, setIsTextBox] = useState(false);
  const [isTimeline, setIsTimeline] = useState(false);
  const [markListNames, setMarkListNames] = useState([]);
  const [titleinputFields, setTitleInputFields] = useState([]);
  const [otherFormData, setOtherFormData] = useState({
    total: 0,
    sgst: 0,
    cgst: 0,
  });
  const [installMent, setInstallMent] = useState({
    Firstinstallmentamount: "",
    Firstinstallmentdate: "",
    Secondinstallmentamount: "",
    Secondinstallmentdate: "",
    Thirdinstallmentamount: "",
    Thirdinstallmentdate: "",
    Fourthinstallmentamount: "",
    Fourthinstallmentdate: "",
  });

  const navigate = useNavigate();

  const BackToListQuotation = () => {
    navigate("/listquotation");
  };

  const RedirectToQuotationform = () => {
    navigate("/quotationform");
  };
  const handleButtonClick = (amount) => {
    var totalAmount = parseFloat(amount);
    console.log('hhh',totalAmount)
    setIsTextBoxOpen(true);

    setTitleInputFields([
      ...titleinputFields,
      {
        title: "",
        subfiled: [],
      },
    ]);

    console.log("titleinputFields", titleinputFields);
    return totalAmount; 
  };

 

  const removeTitleFields = (index) => {
    const rows = [...titleinputFields];
    rows.splice(index, 1);
    setTitleInputFields(rows);
  };
  const [inputFields, setInputFields] = useState([]);
  const [timeline, setTimeline] = useState([]);

  const addInputField = (i) => {
    // alert(i)
    setIsTextBox(true);
    const values = titleinputFields;
    values[i].subfiled.push({
      description: "",
      size: "",
      quantity: "",
      rate: "",
      amount: "",
    });
    setTitleInputFields([...values]);
  };
  const removeInputFields = (index, i) => {
    // alert(i)
    const rows = titleinputFields;
    titleinputFields[index].subfiled.splice(i, 1);
    // rows.splice(i, 1);
    setTitleInputFields([...rows]);
  };

  const handleMainFieldChange = (index, evnt) => {
    const { name, value } = evnt.target;

    const list = titleinputFields;

    list[index][name] = value;
    setTitleInputFields([...list]);
  };

  const handleChange = (index, i, evnt) => {
    const { name, value } = evnt.target;
    const list = titleinputFields;

    list[index].subfiled[i][name] = value;
    setTitleInputFields([...list]);
  };

  const addMoreInputField = () => {
    setIsTimeline(true);
    setTimeline([
      ...timeline,
      {
        task: "",
        days: "",
      },
    ]);
  };
  const removeTimeline = (index) => {
    const rows = [...timeline];
    rows.splice(index, 1);
    setTimeline(rows);
  };
  const TimelineChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...timeline];
    list[index][name] = value;
    setTimeline(list);
  };

  const options = ["Sqft", "Pcs", "Mcs"];
  const onOptionChangeHandler = (event) => {
    console.log("User Selected Value - ", event.target.value);
  };

  const handleOnchangeInstallment = (i, targetvalue) => {
    const { name, value } = targetvalue.target;
    setInstallMent({ ...installMent, [name]: value });
  };

  const calculateInstallmentAmount = (totalAmount) => {
    const installmentAmount = totalAmount / 4; // Calculate 20% of the total
    return installmentAmount;
  };

  const marklistdata = [
    {
      title: "Plywood Company Name",
      items: [
        { title: "Jet Plywood", checked: 0 },
        { title: "Roi Plywood", checked: 0 },
        { title: "Royal Plywood", checked: 0 },
        { title: "Green Plywood", checked: 0 },
        { title: "Redbull Plywood", checked: 0 },
        { title: "Rocktouch Plywood", checked: 0 },
      ],
    },
    {
      title: "Laminate Company Name",
      items: [
        { title: "Green Laminate", checked: 0 },
        { title: "Royal Laminate", checked: 0 },
        { title: "Rox Laminate", checked: 0 },
        { title: "Orange Laminate", checked: 0 },
        { title: "Finetouch Laminate", checked: 0 },
        { title: "Decora Laminate", checked: 0 },
      ],
    },
    {
      title: "Sofa and Curtain Fabric",
      items: [
        { title: "Mr Fabric", checked: 0 },
        { title: "D Decore Fabric", checked: 0 },
        { title: "Maharashtra Rexine", checked: 0 },
        { title: "Bombay Traders", checked: 0 },
        { title: "Saloni Fabric", checked: 0 },
      ],
    },
    {
      title: "Light Fitting and Wire",
      items: [
        { title: "Polycab Wire", checked: 0 },
        { title: "Finolex Wire", checked: 0 },
        { title: "Syska LED Light", checked: 0 },
        { title: "Wipro", checked: 0 },
        { title: "Crompton Lights", checked: 0 },
      ],
    },
    {
      title: "Light Fitting and Wire",
      items: [
        { title: "Ebco", checked: 0 },
        { title: "Hafele India", checked: 0 },
        { title: "Hettich", checked: 0 },
        { title: "Godrej", checked: 0 },
        { title: "Onyx", checked: 0 },
      ],
    },
    {
      title: "False Ceiling Material and Painting",
      items: [
        { title: "Gypsum Board", checked: 0 },
        { title: "Finishing Material", checked: 0 },
        { title: "Luster Paint", checked: 0 },
        { title: "Royal Luxury", checked: 0 },
        { title: "OBD Paint", checked: 0 },
      ],
    },
  ];

  const [MarkListValueAndData, setMarkListValueAndData] = useState(marklistdata);

  const handleChangeCheck = (outerIndex, innerIndex, newValue) => {
    const newData = [...MarkListValueAndData];
    newData[outerIndex].items[innerIndex].checked = newValue;
    setMarkListValueAndData(newData);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customer_name: otherFormData.customer_name,
      phone: otherFormData.phone,
      address: otherFormData.address,
      items: JSON.stringify(titleinputFields),
      mark_list: JSON.stringify(MarkListValueAndData),
      total_amount: otherFormData.total,
      sgst: otherFormData.sgst,
      cgst: otherFormData.cgst,
      installment: JSON.stringify(installMent),
      time_line: JSON.stringify(timeline),
      created_by: "Demo",
    });
console.log("JSON.stringify(timeline)",JSON.stringify(timeline))
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_HAPS_MAIN_BASE_URL + "Quotation/Create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 201) {
          toast.success("Quotation Added Successfully");

          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
      })

      .catch((error) => console.log("error", error));
  };

  const handleTotalChange = (e) => {
    const totalAmount = parseFloat(e.target.value);

    const totalValue = parseFloat(e.target.value);
    const sgstValue = (totalValue * 0.09).toFixed(2); // Calculate SGST (9%)
    const cgstValue = (totalValue * 0.09).toFixed(2); // Calculate CGST (9%)

    setOtherFormData({
      total: totalValue,
      sgst: sgstValue,
      cgst: cgstValue,
    });
    const InstallmentTotal = parseInt(totalValue) + parseInt(sgstValue) + parseInt(cgstValue);
    console.log(InstallmentTotal);
    const installmentAmount = calculateInstallmentAmount(InstallmentTotal);

    setInstallMent({
      Firstinstallmentamount: installmentAmount,
      Secondinstallmentamount: installmentAmount,
      Thirdinstallmentamount: installmentAmount,
      Fourthinstallmentamount: installmentAmount,
      Firstinstallmentdate: "",
      Secondinstallmentdate: "",
      Thirdinstallmentdate: "",
      Fourthinstallmentdate: "",
    });
  };

  return (
    <DashboardLayout>
      {console.log("cj", JSON.stringify(installMent))}
      {console.log("time", JSON.stringify(timeline))}
      <DashboardNavbar />
      <Toaster position="top-center" reverseOrder={false} />
      <Toaster position="top-center" reverseOrder={false} />
      <div className="border-solid border-2 black-indigo-600 mt-6">
        <div style={{ fontSize: 18, fontWeight: "500" }} className="px-8 mt-5">
          Add Quotation
        </div>
        <div style={{ fontSize: 22, fontWeight: "bold" }} className="px-8 mt-6">
          Customer Details
        </div>
        <div className="mt-6">
          <form className="w-full" onSubmit={handleOnSubmit}>
            <div className="flex ">
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">Customer Name</label>
                <input
                  required
                  onChange={(e) =>
                    setOtherFormData({ ...otherFormData, ["customer_name"]: e.target.value })
                  }
                  className="appearance-none block w-full text-sm  text-gray-400 border border-blue-500 rounded px-1.5 py-1.5 leading-tight focus:outline-none "
                  id="grid-first-name"
                  type="text"
                  placeholder="Full Name"
                />
              </div>
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">Phone No</label>
                <input
                  required
                  onChange={(e) =>
                    setOtherFormData({ ...otherFormData, ["phone"]: e.target.value })
                  }
                  className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Phone No"
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">Address</label>
                <textarea
                  required
                  onChange={(e) =>
                    setOtherFormData({ ...otherFormData, ["address"]: e.target.value })
                  }
                  className="appearance-none block w-full  text-sm  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="password"
                  placeholder="Address"
                />
              </div>
            </div>

            <div className=" m-4 border-s-4 border-2 border-black-400 ">
              <div className="flex flex-wrap -mx-3 mb-5 px-4">
                <div className="w-full px-4 ">
                  <label className="text-gray-700 text-xs font-bold mb-2">Items</label>
                  <div className="grid grid-cols-8 text-xs font-bold mt-2">
                    <div>Sr.no</div>
                    <div>Description</div>
                    <div>Size</div>
                    <div>Quantity</div>
                    <div>Type</div>
                    <div>Rate</div>
                    <div>Amount</div>
                    <div>Action</div>
                  </div>
                  <hr />

                  <div>
                    {isTextBoxOpen && (
                      <div className="mt-4">
                        {titleinputFields.map((data, index) => {
                          const { title } = data;
                          return (
                            <div key={index}>
                              <div className="flex" key={index}>
                                <div className="w-full px-0 mt-4">
                                  <input
                                    type="text"
                                    onChange={(e) => handleMainFieldChange(index, e)}
                                    name="title"
                                    className=" text-gray-700 border border-gray-200 rounded py-3 px-48 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                    placeholder="Title"
                                  />

                                  <button
                                    type="button"
                                    className="ml-16 text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
                                    onClick={() => addInputField(index)}
                                  >
                                    Add Row
                                  </button>

                                  <button
                                    className="ml-96"
                                    type="button"
                                    onClick={() => removeTitleFields()}
                                  >
                                    <AiFillCloseCircle style={deletstyle} />
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div>
                                {data.subfiled.map((el, i) => {
                                  
                                  const { description, size, quantity, rate, amount } = el;
                                  console.log("vvvs",el.amount);
                                  return (
                                    <div className="grid grid-cols-8 gap-4 mt-4" key={i}>
                                      <div className="w-full"> {i + 1} </div>
                                      <div>
                                        <input
                                          type="text"
                                          onChange={(evnt) => handleChange(index, i, evnt)}
                                          value={description}
                                          name="description"
                                          placeholder="Description"
                                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                          id="grid-text"
                                        />
                                      </div>

                                      <div>
                                        <input
                                          type="text"
                                          onChange={(evnt) => handleChange(index, i, evnt)}
                                          value={size}
                                          name="size"
                                          placeholder="0"
                                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                          id="grid-text"
                                        />
                                      </div>

                                      <div>
                                        <input
                                          type="text"
                                          onChange={(evnt) => handleChange(index, i, evnt)}
                                          value={quantity}
                                          name="quantity"
                                          placeholder="0"
                                          disabled
                                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                          id="grid-text"
                                        />
                                      </div>

                                      <div>
                                        {" "}
                                        <select
                                          className="appearance-none block w-1/2 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                          id="grid-text"
                                          onChange={onOptionChangeHandler}
                                        >
                                          <option>select</option>
                                          {options.map((option, index) => {
                                            return <option key={index}>{option}</option>;
                                          })}
                                        </select>
                                      </div>
                                      <div>
                                        <input
                                          type="text"
                                          onChange={(evnt) => handleChange(index, i, evnt)}
                                          value={rate}
                                          name="rate"
                                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                          id="grid-text"
                                        />
                                      </div>

                                      <div>
                                        <input
                                          type="text"
                                          onChange={(evnt) => handleChange(index, i, evnt)}
                                          value={amount}
                                          name="amount"
                                          placeholder="0"
                                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                          id="grid-text"
                                        />
                                      </div>

                                      <div>
                                        <div className="rounded-t-lg text-xs font-bold rounded-full">
                                          <button
                                            type="button"
                                            className="grid grid-cols-8 mt-4"
                                            onClick={() => removeInputFields(index, i)}
                                          >
                                            <AiFillCloseCircle style={deletstyle} />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                <hr />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <hr />
                  </div>
                  <div className="rounded-t-lg text-xs mt-4 font-bold rounded-full">
                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleButtonClick()}
                    >
                      Add Title
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className=" m-4 border-s-4 border-2 border-black-400 ">
              <div className="flex flex-wrap -mx-3 mb-5 px-4">
                <div className="w-full px-4 ">
                  <label className="text-gray-700 text-xs font-bold mb-2">Mark List</label>
                  {marklistdata.map((ma, index) => {
                    return (
                      <>
                        <div className="grid grid-cols-2  text-xs font-bold mt-4" key={index}>
                          <div>{ma.title}</div>
                          <div className="text-center">Mark</div>
                        </div>
                        <hr />
                        {ma.items.map((da, io) => {
                          return (
                            <div className="grid grid-cols-2  text-xs  mt-4" key={io}>
                              <div>{da.title}</div>
                              <div className="text-center">
                                <input
                                  id="vue-checkbox"
                                  type="checkbox"
                                  value={da.title}
                                  onChange={(e) => handleChangeCheck(index, io, 1)}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}

                  <div className="border-s-4 border-2 border-black-400 mt-6">
                    <div className="flex flex-wrap -mx-3 mb-5 px-4">
                      <div className="w-full px-4 ">
                        <label className="text-gray-700 text-xs font-bold mb-2">Total</label>
                        <div className="grid grid-cols-6 text-xs mt-4">
                          <div>Total</div>
                          <div className="text-left">
                            <input
                              type="text"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              defaultValue={otherFormData.total}
                              onChange={handleTotalChange}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-6 text-xs mt-4">
                          <div>SGST(9%)</div>
                          <div className="text-left">
                            <input
                              type="text"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              value={otherFormData.sgst}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-6 text-xs mt-4">
                          <div>CGST(9%)</div>
                          <div className="text-left">
                            <input
                              type="text"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              value={otherFormData.cgst}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-6 text-xs mt-4">
                          <div>Total Amount</div>
                          <div className="text-left">
                            <input
                              type="text"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              value={
                                parseFloat(otherFormData.total) +
                                parseFloat(otherFormData.sgst) +
                                parseFloat(otherFormData.cgst)
                              }
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-s-4 border-2 border-black-400 mt-6">
                    <div className="flex flex-wrap -mx-3 mb-5 px-4">
                      <div className="w-full px-4 ">
                        <label className="text-gray-700 text-xs font-bold mb-2">Installment</label>
                        <div className="grid grid-cols-4 text-xs mt-4">
                          <div>Total</div>
                          <div>%</div>
                          <div>Amount</div>
                          <div>Date</div>
                        </div>

                        <div className="grid grid-cols-4 text-xs mt-4">
                          <div>1st Installment</div>
                          <div>25%</div>
                          <div>
                            <input
                              required
                              type="text"
                              name="Firstinstallmentamount"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              value={installMent.Firstinstallmentamount}
                              onChange={handleOnchangeInstallment}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) => handleOnchangeInstallment(3, e)}
                              type="date"
                              name="Firstinstallmentdate"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>2nd Installment</div>
                          <div>25%</div>
                          <div>
                            <input
                              required
                              type="text"
                              name="Secondinstallmentamount"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              value={installMent.Secondinstallmentamount}
                              onChange={handleOnchangeInstallment}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) => handleOnchangeInstallment(3, e)}
                              type="date"
                              name="Secondinstallmentdate"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>3rd Installment</div>
                          <div>25%</div>
                          <div>
                            <input
                              required
                              type="text"
                              name="Thirdinstallmentamount"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              value={installMent.Thirdinstallmentamount}
                              onChange={handleOnchangeInstallment}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) => handleOnchangeInstallment(3, e)}
                              type="date"
                              name="Thirdinstallmentdate"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>4th Installment</div>
                          <div>25%</div>
                          <div>
                            <input
                              required
                              type="text"
                              name="Firstinstallmentamount"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                              value={installMent.Fourthinstallmentamount}
                              onChange={handleOnchangeInstallment}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) => handleOnchangeInstallment(3, e)}
                              type="date"
                              name="Fourthinstallmentdate"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>

                  <div className="border-s-4 border-2 border-black-400 mt-6">
                    <div className="flex flex-wrap -mx-3 mb-5 px-4">
                      <div className="w-full px-4 ">
                        <label className="text-gray-700 text-xs font-bold mb-2">Timeline</label>
                        <div className="grid grid-cols-3  text-xs  mt-4">
                          <div>Sr.No</div>
                          <div>Task</div>
                          <div>Days</div>
                        </div>
                        <hr />

                        {/*
                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>{1}</div>

                          <div>
                            <input type="text" className="bg-gray-50 border w-72 h-10  border-gray-300 rounded-lg block p-2.5" placeholder="Desciption" />
                          </div>
                          <div className='ml-16'>
                            <input type="text" className="bg-gray-50 border w-72 h-10 border-gray-300 rounded-lg block  p-2.5" placeholder="0" />
                          </div>
                      </div> */}
                        <div>
                          {isTimeline && (
                            <>
                              {timeline.map((data, index) => {
                                const { task, days } = data;
                                return (
                                  <div className="grid grid-cols-4 text-xs mt-4" key={index}>
                                    <div className="w-1/2"> {index + 1} </div>
                                    <div>
                                      <input
                                        type="text"
                                        onChange={(evnt) => TimelineChange(index, evnt)}
                                        name="task"
                                        placeholder="Description"
                                        className="bg-gray-50 border w-72 h-10  border-gray-300 rounded-lg block  p-2.5"
                                      />{" "}
                                    </div>

                                    <div className="ml-16">
                                      <input
                                        type="text"
                                        onChange={(evnt) => TimelineChange(index, evnt)}
                                        name="days"
                                        placeholder="0"
                                        className="bg-gray-50 border w-72 h-10  border-gray-300 rounded-lg block  p-2.5"
                                      />
                                    </div>

                                    <div>
                                      <div className="ml-48">
                                        <button
                                          type="button"
                                          className="grid grid-cols-2 mt-4"
                                          onClick={() => removeTimeline()}
                                        >
                                          <AiFillCloseCircle style={deletstyle} />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}{" "}
                          <hr />
                        </div>

                        <hr></hr>

                        <div className="grid grid-cols-6 text-xs  mt-4">
                          <div className="rounded-t-lg text-xs font-bold rounded-full">
                            <button
                              type="button"
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                              onClick={() => addMoreInputField()}
                            >
                              Add More
                            </button>
                          </div>

                          <div>Total Days </div>
                          <div>
                            <input
                              type="text"
                              className="bg-gray-50 border w-72 h-10 disabled border-gray-300 rounded-lg block w-1/2 p-2.5"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="m-3 grid grid-cols-3 text-xs  mt-4">
                <div>
                  <button
                    type="text"
                    className=" m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={BackToListQuotation}
                  >
                    Back
                  </button>

                  <button
                    className="m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    type="submit"
                  >
                    Submit
                  </button>

                  <button
                    className=" m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={RedirectToQuotationform}
                  >
                    Make copy
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CustomerQuotation;
