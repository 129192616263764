import React, { useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AiFillCloseCircle } from 'react-icons/ai';
import { Toaster, toast } from 'react-hot-toast';

const Staff = () => {


  const navigate = useNavigate()
  const [staffData, setStaffData] = useState({});
  const [FilesToUpload, setFilesToUpload] = useState({})
  const [isLoading, setIsLoading] = useState({
    ["adhaar_file"]: false,
    ["pan_file"]: false
  });


  const RedirectToListStaff = () => {
    navigate("/liststaff")
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "username": staffData.username,
      "password": staffData.password,
      "name": staffData.name,
      "phone": staffData.phone,
      "email": staffData.email,
      "aadhaar_no": staffData.aadhaar_no,
      "pan_no": staffData.pan_no,
      "adhaar_file": staffData.adhaar_file,
      "pan_file": staffData.pan_file
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_HAPS_MAIN_BASE_URL + "Staff/Create", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("result", result);
        if (result.Status === 201) {
          toast.success("Staff Added Successfully")
          setTimeout(() => {
            window.location.reload(false)
          }, 1000);
        }
      })
      .catch(error => console.log('error', error));
  }
  const HandleUploadFile = async (type) => {
    var formdata = new FormData();
    if (type === 1) {
      setIsLoading({ ...isLoading, ["pan_file"]: true });
      formdata.append("file", FilesToUpload.pan_file);
    } else {
      setIsLoading({ ...isLoading, ["adhaar_file"]: true });
      formdata.append("file", FilesToUpload.adhaar_file);
    }

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_HAPS_MAIN_BASE_URL + "Staff/FileUpload", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.Status === 200) {
          if (type === 1) {
            setStaffData({ ...staffData, ["pan_file"]: result.path })
            setIsLoading({ ...isLoading, ["pan_file"]: false });
            toast.success("File Uploaded Successfully")
          } else {
            setStaffData({ ...staffData, ["adhaar_file"]: result.path })
            setIsLoading({ ...isLoading, ["adhaar_file"]: false });
            toast.success("File Uploaded Successfully")
          }
        }
      })
      .catch(error => console.log('error', error));
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="border-solid border-2 black-indigo-600 mt-6">
        <div style={{ fontSize: 15, }} className="px-8 mt-5 font-bold">
          Add Staff
        </div>
        <div className='mt-6'>
          <form className="w-full" onSubmit={handleOnSubmit}>
            <div className="flex ">
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  Staff Username
                </label>
                <input required onChange={(e) => setStaffData({ ...staffData, ["username"]: e.target.value })} className="appearance-none block w-full text-sm  text-gray-700 border border-blue-500 rounded px-1.5 py-1.5 leading-tight focus:outline-none " id="grid-first-name" type="text" placeholder="Full Name" />

              </div>
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  Staff Password
                </label>
                <input required onChange={(e) => setStaffData({ ...staffData, ["password"]: e.target.value })} className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="password" placeholder="***********" />
              </div>
            </div>

            <div className="flex ">
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  Full Name
                </label>
                <input required onChange={(e) => setStaffData({ ...staffData, ["name"]: e.target.value })} className="appearance-none block w-full text-sm  text-gray-700 border  rounded px-1.5 py-1.5 leading-tight focus:outline-none " id="grid-full-name" type="text" placeholder="Full Name" />

              </div>
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  Phone No.
                </label>
                <input required onChange={(e) => setStaffData({ ...staffData, ["phone"]: e.target.value })} className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-phone-no" type="text" placeholder="000-000-0000" />
              </div>
            </div>

            <div className="flex">
              <div className="w-1/2 px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  Email
                </label>
                <input required onChange={(e) => setStaffData({ ...staffData, ["email"]: e.target.value })} className="appearance-none block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5  mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" placeholder="Example@gmail.com" />
              </div>
            </div>

            <div className="flex ">
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  Aadhar No.
                </label>
                <input required onChange={(e) => setStaffData({ ...staffData, ["aadhaar_no"]: e.target.value })} className="block w-full text-sm  text-gray-700 border rounded px-1.5 py-1.5 leading-tight " id="grid-aadhar-no" type="text" placeholder="0000-0000-0000" />

              </div>
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  Pan No.
                </label>
                <input required onChange={(e) => setStaffData({ ...staffData, ["pan_no"]: e.target.value })} className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:bg-white focus:border-gray-500" id="grid-pan-no" type="text" placeholder="pan no" />
              </div>
            </div>

            <div className="flex px-4">
              <div className="w-full mx-5 mt-5 mb-2">
                <label className="text-gray-700 text-lg font-bold mb-2">
                  Document
                </label>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-8 px-4">
              <div className="w-full">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  PanCard
                </label>
                <div className='flex flex-row'>
                  <input required onChange={(e) => setFilesToUpload({ ...FilesToUpload, ["pan_file"]: e.target.files[0] })} className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:bg-white focus:border-gray-500" id="grid-pancard" type="file" />
                  {
                    FilesToUpload.pan_file ?
                      <div className="flex items-center">
                        <button
                          type='button'
                          className='py-1 px-5 bg-black text-white rounded-r-[25px]'
                          onClick={() => HandleUploadFile(1)}
                        >
                          {isLoading.pan_file ? <div className="ml-2">Loading...</div> : "Upload"}
                        </button>

                      </div> : ""
                  }

                </div>
              </div>

              <div className="w-full">
                <label className="text-gray-700 text-xs font-bold mb-2">
                  AadharCard
                </label>
                <div className='flex flex-row'>
                  <input required onChange={(e) => setFilesToUpload({ ...FilesToUpload, ["adhaar_file"]: e.target.files[0] })} className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:bg-white focus:border-gray-500" id="grid-pancard" type="file" />
                  {
                    FilesToUpload.adhaar_file ?
                      <div className="flex items-center">
                        <button
                          type='button'
                          className='py-1 px-5 bg-black text-white rounded-r-[25px]'
                          onClick={() => HandleUploadFile(2)}
                        >
                          {isLoading.adhaar_file ? <div className="ml-2">Loading...</div> : "Upload"}
                        </button>

                      </div> : ""
                  }
                </div>
              </div>
            </div>
            <div className="m-3 grid grid-cols-3 text-xs  mt-4">
              <div>
                <button type='button' className=" m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={RedirectToListStaff}>Back</button>
                <button type='submit' className="m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Staff
