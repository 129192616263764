// @mui material components
import Card from "@mui/material/Card";

// Arrange Free React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Arrange Free React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

import React, { useState, useEffect } from "react";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import Loader from "layouts/loader/Loader";
import NontAuthorized401 from "NontAuthorized401";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function ContactLeads() {
  let Navigate = useNavigate();

  {
    /* const [customer, setCustomer] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(10);
const [customerDetails, setCustomerDetails] = useState(null);

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = (indexOfFirstItem, indexOfLastItem); */
  }
  const [modalVisible, setModalVisible] = useState(false);
  const [fullMessage, setFullMessage] = useState("");
  const handleViewClick = (message) => {
    setFullMessage(message);
    setModalVisible(true);
  };

  const [lead, setLead] = useState([]);
  const [loading, setLoading] = useState(true);
  

  // list leads
  const listLeads = async () => {
    setLoading(true);
      var requestOptions = {
          method: 'GET',
          redirect: 'follow'
      };

      await fetch(process.env.REACT_APP_HAPS_MAIN_BASE_URL + "customer/getAllContactUs", requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result)
              setLead(result.data)
              if (result.message === "Success") {
                setLoading(false);
            }
          })
          .catch(error => console.log('error', error));
  }

  const exportToExcel = () => {
    const table = document.getElementById("table-to-export");
    const rows = table.getElementsByTagName("tr");
    let csvContent = "data:text/csv;charset=utf-8,";

    for (const row of rows) {
      const rowData = [];
      const cols = row.getElementsByTagName("td");

      for (const col of cols) {
        rowData.push(col.innerText);
      }

      csvContent += rowData.join(",") + "\n";
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "contacts_leads.csv");
    document.body.appendChild(link);
    link.click();
  };


  useEffect(() => {
      listLeads()
  }, [])


  return (
    <>
    {localStorage.getItem("Token") ? (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <>
          <div className="relative bg-white h-screen overflow-hidden" />
          {loading && (
            <div className="flex justify-center">
              <div className="absolute top-[30%]">
                <Loader />
              </div>
            </div>
          )}
        </>
      ) : (
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex justify-between mb-4">
                 
                 
                  <div>
                    {" "}
                    {/* Right-side content */}
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        table="table-to-export"
                        filename="Contacts_Leads_Sheet"
                        sheet="tablexls"
                      />
                    </button>
                  </div>
                </div>
                <table
                  id="table-to-export"
                  className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                >
          
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Sr No
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Message
                </th>
                <th scope="col" className="px-6 py-3">
                  Date / Time 
                </th>
              </tr>
            </thead>
            <tbody>
              {lead.map((el, index) => (
                <tr
                  key={el.id}
                  className={`border-b dark:bg-gray-800 dark:border-gray-700 ${
                    index % 2 === 0
                      ? "odd:bg-white even:bg-gray-50"
                      : "odd:dark:bg-gray-800 even:dark:bg-gray-700"
                  }`}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {/* {(currentPage - 1) * itemsPerPage + index + 1} */}
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">
                    <a>{el.name}</a>
                  </td>
                  <td className="px-6 py-4 ">{el.contact_number}</td>
                  <td className="px-6 py-4 ">{el.email_id}</td>
                  <td className="px-6 py-4" style={{ wordWrap: 'break-word', maxWidth: '200px' }}>
                    {el.message.split(" ").slice(0, 10).join(" ")}
                    {el.message.split(" ").length > 10 && (
                      <button
                        className="text-blue-500 underline ml-2"
                        onClick={() => handleViewClick(el.message)}
                      >
                        View
                      </button>
                    )}
                  
                  </td>
                  <td className="px-6 py-4 ">{el.created_at}</td>
                  {modalVisible && (
                    <Modal
                      title="Message"
                      open={modalVisible}
                      onCancel={() => setModalVisible(false)}
                      footer={null}
                      maskStyle={{backgroundColor: "transparent"}}
                    >
                      <p>{fullMessage}</p>
                    </Modal>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="flex justify-center mt-4">
        <div className="flex">
          {(currentPage === 1) ? '' : <button
            onClick={() => paginate(currentPage - 1)}
            className={`px-3 mx-3 rounded-md w-[110px] focus:outline-none bg-black text-white text-md ${currentPage === 1 ? 'cursor-not-allowed' : ''}`}
          // disabled={currentPage === 1}
          >
            Previous
          </button>}
          {(currentPage !== pageCount) ? <button
            onClick={() => paginate(currentPage + 1)}
            className={`px-3 mx-3 rounded-md w-[110px] focus:outline-none bg-black text-white text-md ${currentItems.length < itemsPerPage ? 'cursor-allowed' : ''}`}
          // disabled={currentItems.length < itemsPerPage}
          >
            Next
          </button> : ""}

        </div>
      </div> */}
      </div>
      )}    
      {/* <Footer /> */}
    </DashboardLayout>
    ) : (
      <NontAuthorized401 />
    )}
  </>
  );
}

export default ContactLeads;
