import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const Subscribers = () => {
  let [searchParam] = useSearchParams();

  const [Subscribers, setSubscribers] = useState([]);

  const getDetails = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_HAPS_MAIN_BASE_URL +
        `subscription/getMumbershipByCardId/${searchParam.get("id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setSubscribers(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="border-solid border-2 black-indigo-600 mt-6">
        <div style={{ fontSize: 15 }} className="px-8 mt-5">
          List Subscription
        </div>
        <div>
          <table className="min-w-full text-left text-sm font-light">
            <thead className="border-b font-medium dark:border-neutral-500">
              <tr>
                <th scope="col" className="px-6 py-4">
                  Sr.No
                </th>
                <th scope="col" className="px-6 py-4">
                  Card Number
                </th>
                <th scope="col" className="px-6 py-4">
                  Start Date
                </th>
                <th scope="col" className="px-6 py-4">
                  Renewal Date
                </th>
                <th scope="col" className="px-6 py-4">
                  Subscription Amount
                </th>
                <th scope="col" className="px-6 py-4">
                  Subscription Type
                </th>
              </tr>
            </thead>
            <tbody>
              {Subscribers &&
                Subscribers.map((el, i) => {
                  return (
                    <tr className="border-b dark:border-neutral-500" key={i}>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">{i + 1}</td>
                      <td className="whitespace-nowrap px-6 py-4">{el.card_number}</td>
                      <td className="whitespace-nowrap px-6 py-4">{el.start_date}</td>
                      <td className="whitespace-nowrap px-6 py-4">{el.renewal_date}</td>
                      <td className="whitespace-nowrap px-6 py-4">{el.subscription_amount}</td>
                      <td className="whitespace-nowrap px-6 py-4">{el.subscription_type}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Subscribers;
