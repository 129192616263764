// Arrange Free React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Arrange Free React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import Product from "layouts/product/Product";
import ProductList from "layouts/product/ProductList";
import ProductUpdate from "layouts/product/ProductUpdate";
import CustomerList from "layouts/Customer/CustomerList";
import Discount from "layouts/product/Discount";
import CustomerDetails from "layouts/Customer/CustomerDetails";
import AddCategory from "layouts/category/AddCategory";
import ListCategory from "layouts/category/ListCategory";
import PromoCode from "layouts/coupn/PromoCode";
import CouponList from "layouts/coupn/CouponList";
import OrderList from "layouts/orders/OrderList";
import ContactLeads from "layouts/contact-leds/ContactLeads";
import SubCategory from "layouts/category/SubCategory";
import ListSubCategory from "layouts/category/ListSubCategory";
import MainBannerImages from "layouts/bannerImages/MainBannerImages";
import AddVendors from "layouts/vendors/AddVendors";
import VendorList from "layouts/vendors/VendorList";
import ReviewList from "layouts/reviews/ReviewList";
import Offer from "layouts/offer/Offer";
import OfferList from "layouts/offer/OfferList";
import CreateRole from "layouts/privilages/CreateRole";
import UpdateRole from "layouts/privilages/UpdateRole";
import AdminRegistration from "layouts/admin/AdminRegistration";
import UpdateAdminRegistration from "layouts/admin/UpdateAdminRegistration";
import AdminList from "layouts/admin/AdminList";
import IndependanceCampaign from "layouts/campaign/IndependanceCampaign";
// import OrderDashboard from "layouts/orders/OrderDashboard";
// import VendorAdd from "layouts/vendors/VendorAdd";
import ProductDashboard from "layouts/product/ProductDashboard";
import Complain from "layouts/complain/Complain";
import Transactions from "layouts/transactions/Transactions";
import CreateNotification from "layouts/notification/CreateNotification";
import {
  FaBell,
  FaCircleNotch,
  FaKey,
  FaList,
  FaListAlt,
  FaMoneyBill,
  FaPhone,
  FaPhoneAlt,
  FaRegImages,
  FaTicketAlt,
  FaTv,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { AiFillShop, AiOutlineExclamation, AiTwotoneShop } from "react-icons/ai";
import { BiCategoryAlt, BiSolidOffer } from "react-icons/bi";
import GetNotification from "layouts/notification/GetNotification";
import InteriorLeads from "layouts/interior-leads/InteriorLeads";
import { BsFillCalendarEventFill } from "react-icons/bs";
import CustomerQuotation from "layouts/quotation/CustomerQuotation";
import ListQuotation from "layouts/quotation/ListQuotation";
import QuotationForm from "layouts/quotation/Quotationform";
import CustomerEditQuotation from "layouts/quotation/CustomerEditQuotation";
import Staff from "layouts/staff/Staff";
import ListStaff from "layouts/staff/Liststaff";
import TaskForce from "layouts/taskforce/TaskForce";
import AddSubscription from "layouts/subscription/AddSubscription";
import ListSubscription from "layouts/subscription/ListSubscription";
import Subscribers from "layouts/subscription/Subscribers";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    accessKey: "a14bc83bb7c780880386323d892b836df8802ae94f98ec127165e6a5739e3333",
    route: "/dashboard",
    icon: <FaTv size="17px" />,
    component_name: "Dashboard",
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <FaMoneyBill size="17px" />,
    component_name: "Billing",
    component: <Billing />,
    noCollapse: true,
  },

  {
    type: "title",
    title: "Product Pages",
    key: "product-pages",
    accessKey: "36c596f0cb5516dc4dd83d4656f755b246e5f7ae1a1d758e71d584a216ff7340",
    icon: <AiTwotoneShop size="17px" />,
    children: [
      {
        type: "collapse",
        name: "Product Dashboard",
        key: "product-dashboard",
        route: "/product-dashboard",
        icon: <FaCircleNotch size="12px" />,
        component_name: "ProductDashboard",
        component: <ProductDashboard />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Add Product",
        key: "product",
        route: "/product",
        icon: <FaCircleNotch size="12px" />,
        component_name: "Product",
        component: <Product />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Product List",
        key: "product-list",
        route: "/product-list",
        icon: <FaCircleNotch size="12px" />,
        component_name: "ProductList",
        component: <ProductList />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Coupons",
    key: "coupon-pages",
    accessKey: "a01588708acbbb71b230b0cfae9919066d1ff6d9e349b86b74c53e69bb76f45c",
    icon: <FaTicketAlt size="17px" />,
    children: [
      {
        type: "collapse",
        name: "Create Coupon",
        key: "promo-code",
        route: "/promo-code",
        icon: <FaCircleNotch size="12px" />,
        component_name: "PromoCode",
        component: <PromoCode />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Coupon List",
        key: "coupon-list",
        route: "/coupon-list",
        icon: <FaCircleNotch size="12px" />,
        component_name: "CouponList",
        component: <CouponList />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Discount",
        key: "discount",
        route: "/discount",
        icon: <FaCircleNotch size="12px" />,
        component_name: "Discount",
        component: <Discount />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Category",
    key: "category-pages",
    accessKey: "5e38265f731baf75d2d8bcb118f79cd324ebb58ddc816afc34d67ee53bad098b",
    icon: <BiCategoryAlt size="17px" />,
    children: [
      {
        type: "collapse",
        name: "Add Category",
        key: "add-category",
        route: "/add-category",
        icon: <FaCircleNotch size="12px" />,
        component_name: "AddCategory",
        component: <AddCategory />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "List Category",
        key: "list-category",
        route: "/list-category",
        icon: <FaCircleNotch size="12px" />,
        component_name: "ListCategory",
        component: <ListCategory />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Sub Category",
        key: "sub-category",
        route: "/sub-category",
        icon: <FaCircleNotch size="12px" />,
        component_name: "SubCategory",
        component: <SubCategory />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "List Sub Category",
        key: "lis-sub-category",
        route: "/list-sub-category",
        icon: <FaCircleNotch size="12px" />,
        component_name: "ListSubCategory",
        component: <ListSubCategory />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Banner Image",
    key: "banner-image",
    accessKey: "7fa921d1fddcc31bc4361475e3a89781f22d9f10dc08d609332f87eff985c21b",
    icon: <FaRegImages size="17px" />,
    children: [
      {
        type: "collapse",
        name: "Banner Image",
        key: "main-banner-image",
        route: "/banner-image",
        icon: <FaCircleNotch size="12px" />,
        component_name: "MainBannerImages",
        component: <MainBannerImages />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Offres",
    key: "offer-page",
    accessKey: "f24bb522dcb5ddcd282e198629367552bcdcbcbddda7352ad5ade667c0980f9b",
    icon: <BiSolidOffer size="17px" />,
    children: [
      {
        type: "collapse",
        name: "Offers",
        key: "offer",
        route: "/offer",
        icon: <FaCircleNotch size="12px" />,
        component_name: "Offer",
        component: <Offer />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Offers List",
        key: "offer-list",
        route: "/list-offer",
        icon: <FaCircleNotch size="12px" />,
        component_name: "OfferList",
        component: <OfferList />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Customer",
    key: "customer-pages",
    accessKey: "d0145f5223d409e0a8e891fccbe821babc0be79872284f02b8b9597e62d81824",
    icon: <FaUsers size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Customer List",
        key: "customer-list",
        route: "/customer-list",
        icon: <FaCircleNotch size="12px" />,
        component_name: "CustomerList",
        component: <CustomerList />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Review List",
        key: "review-list",
        route: "/review-list",
        icon: <FaCircleNotch size="12px" />,
        component_name: "ReviewList",
        component: <ReviewList />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Orders",
    key: "order-pages",
    accessKey: "8e213582f50fe730ad9fc6ac69b3e28b71b26f0113ae1c358c3ffc4bb96acca5",
    icon: <FaList size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Order List",
        key: "order-list",
        route: "/order-list",
        icon: <FaCircleNotch size="12px" />,
        component_name: "OrderList",
        component: <OrderList />,
        noCollapse: true,
      },
      // {
      //   type: "collapse",
      //   name: "Dashboard",
      //   key: "order-dashboard",
      //   route: "/order-dashboard",
      //   icon: <CreditCard size="12px" />,
      //   component_name: "OrderDashboard",
      //   component: <OrderDashboard />,
      //   noCollapse: true,
      // },
    ],
  },

  {
    type: "title",
    title: "Vendors",
    key: "vendors-pages",
    accessKey: "a14bc83bb7c780880386323d892b836df8802ae94f98ec127165e6a5739e3333",
    icon: <AiFillShop size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Add Vendors",
        key: "add-vendors",
        route: "/add-vendors",
        icon: <FaCircleNotch size="12px" />,
        component_name: "AddVendors",
        component: <AddVendors />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "List Vendors",
        key: "list-vendors",
        route: "/list-vendors",
        icon: <FaCircleNotch size="12px" />,
        component_name: "VendorList",
        component: <VendorList />,
        noCollapse: true,
      },
      // {
      //   type: "collapse",
      //   name: "Vendors",
      //   key: "vendor-add",
      //   route: "/vendor-add",
      //   icon: <CreditCard size="12px" />,
      //   component_name: "VendorAdd",
      //   component: <VendorAdd />,
      //   noCollapse: true,
      // },
    ],
  },

  {
    type: "title",
    title: "Leads",
    key: "leads-page",
    accessKey: "6b0c41c07e9953fe59e773eedb07f82e0ead50142403621167af66d33465fe28",
    icon: <FaPhoneAlt size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Contact Leads",
        key: "contact-leads",
        route: "/contact-leads",
        icon: <FaCircleNotch size="12px" />,
        component_name: "ContactLeads",
        component: <ContactLeads />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Interior Leads",
        key: "interior-leads",
        route: "/interior-leads",
        icon: <FaCircleNotch size="12px" />,
        component_name: "InteriorLeads",
        component: <InteriorLeads />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Task Force",
        key: "interior-leads",
        route: "/taskforce",
        icon: <FaCircleNotch size="12px" />,
        component_name: "TaskForce",
        component: <TaskForce />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Privilages",
    key: "Privilage-page",
    accessKey: "85a41e2eb300c96513867d238faabd7f1c438bca9fb7edafc09b7286700da02c",
    icon: <FaUser size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Create Role",
        key: "create-role",
        route: "/create-role",
        icon: <FaCircleNotch size="12px" />,
        component_name: "CreateRole",
        component: <CreateRole />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Update Role",
        key: "update-role",
        route: "/update-role",
        icon: <FaCircleNotch size="12px" />,
        component_name: "UpdateRole",
        component: <UpdateRole />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Admin",
    key: "admin-page",
    accessKey: "21c895af98e472d757bdbad206d58ff3f45355c0a83af9e56d160d837e76e839",
    icon: <FaKey size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Admin Registration",
        key: "admin-registration",
        route: "/admin-registration",
        icon: <FaCircleNotch size="12px" />,
        component_name: "AdminRegistration",
        component: <AdminRegistration />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Admin List",
        key: "admin-list",
        route: "/admin-list",
        icon: <FaCircleNotch size="12px" />,
        component_name: "AdminList",
        component: <AdminList />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Campaign",
    key: "campaign",
    accessKey: "9cf96edd33553cfbf06542087140e0430be572b4f2ba418bf8e608749dae58d0",
    icon: <BsFillCalendarEventFill size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Independance Campaign",
        key: "independance-campaign",
        route: "/independance-campaign",
        icon: <FaCircleNotch size="12px" />,
        component_name: "IndependanceCampaign",
        component: <IndependanceCampaign />,
        noCollapse: true,
      },
    ],
  },

  {
    type: "title",
    title: "Notifications",
    key: "notifications",
    accessKey: "ba63b41f4bf1ad592249a77dc88da7443ebd69c950cdf2b0c002c5efa85640c1",
    icon: <FaBell size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Create Notification",
        key: "create-notification",
        route: "/create-notification",
        icon: <FaCircleNotch size="12px" />,
        component_name: "CreateNotification",
        component: <CreateNotification />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Get Notification",
        key: "get notification",
        route: "/get-notification",
        icon: <FaCircleNotch size="12px" />,
        component_name: "GetNotification",
        component: <GetNotification />,
        noCollapse: true,
      },
    ],
  },
  {
    type: "title",
    title: "complain",
    key: "complain",
    accessKey: "7fa921d1fddcc31bc4361475e3a89781f22d9f10dc08d609332f87eff985c21b",
    icon: <AiOutlineExclamation size="12px" />,
    children: [
      {
        type: "collapse",
        name: "complains",
        key: "main-complain-image",
        route: "/complain",
        icon: <FaCircleNotch size="12px" />,
        component_name: "complain",
        component: <Complain />,
        noCollapse: true,
      },
    ],
  },
  {
    type: "title",
    title: "Transactions",
    key: "Transactions",
    accessKey: "7fa921d1fddcc31bc4361475e3a89781f22d9f10dc08d609332f87eff985c21b",
    icon: <CreditCard size="12px" />,
    children: [
      {
        type: "collapse",
        name: "transactions",
        key: "main-transactions",
        route: "/transactions",
        icon: <CreditCard size="12px" />,
        component_name: "transactions",
        component: <Transactions />,
        noCollapse: true,
      },
     
    ],
  },
  {
    type: "title",
    title: "Quotation",
    key: "Quotation",
    accessKey: "7fa921d1fddcc31bc4361475e3a89781f22d9f10dc08d609332f87eff985c21b",
    icon: <CreditCard size="12px" />,
    children: [
      {
        type: "collapse",
        name: "Customerquotation",
        key: "Customerquotation",
        route: "/customerquotation",
        icon: <CreditCard size="12px" />,
        component_name: "quotation",
        component: <CustomerQuotation />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Listquotation",
        key: "listquotation",
        route: "/listquotation",
        icon: <CreditCard size="12px" />,
        component_name: "Listquotation",
        component: <ListQuotation />,
        noCollapse: true,
      },
      // {
      //   type: "collapse",
      //   name: "Quotationform",
      //   key: "Quotationform",
      //   route: "/quotationform",
      //   icon: <CreditCard size="12px" />,
      //   component_name: "Quotationform",
      //   component: <QuotationForm />,
      //   noCollapse: true,
      // },
      
    ],
  },

  {
    type: "title",
    title: "Staff",
    key: "Staff",
    accessKey: "7fa921d1fddcc31bc4361475e3a89781f22d9f10dc08d609332f87eff985c21b",
    icon: <CreditCard size="12px" />,
    children: [
      {
        type: "collapse",
        name: "AddStaff",
        key: "Staff",
        route: "/addstaff",
        icon: <CreditCard size="12px" />,
        component_name: "Addtransactions",
        component: <Staff />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "ListStaff",
        key: "ListStaff",
        route: "/ListStaff",
        icon: <CreditCard size="12px" />,
        component_name: "ListStaff",
        component: <ListStaff />,
        noCollapse: true,
      },
     
    ],
  },
  {
    type: "title",
    title: "Subscription",
    key: "Subscription",
    accessKey: "7fa921d1fddcc31bc4361475e3a89781f22d9f10dc08d609332f87eff985c21b",
    icon: <CreditCard size="12px" />,
    children: [
      {
        type: "collapse",
        name: "AddSubscription",
        key: "Subscription",
        route: "/addsubscription",
        icon: <CreditCard size="12px" />,
        component_name: "Addtransactions",
        component: <AddSubscription />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "ListSubscription",
        key: "ListSubscription",
        route: "/listsubscription",
        icon: <CreditCard size="12px" />,
        component_name: "ListSubscription",
        component: <ListSubscription />,
        noCollapse: true,
      },
      // {
      //   type: "collapse",
      //   name: "Subscribers",
      //   key: "Subscribers",
      //   route: "/Subscribers",
      //   icon: <CreditCard size="12px" />,
      //   component_name: "Subscribers",
      //   component: <Subscribers />,
      //   noCollapse: true,
      // },
     
    ],
  },
];

export default routes;
