import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { FaCross, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

function AddSubscription() {
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [OneBhk, setOneBhk] = useState("");
  const [TwoBhk, setTwoBhk] = useState("");
  const [ThreeBhk, setThreeBhk] = useState("");
  const [FourBhk, setFourBhk] = useState("");
  const [FiveBhk, setFiveBhk] = useState("");
  const [Villa, setVilla] = useState("");
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    title: "",
    description: "",
    fields: [{ subTitle: "", subDesc: "" }],
  });

  const [Array, setArray] = useState([
    {
      title: "",
      description: "",
      fields: [{ subTitle: "", subDesc: "" }],
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem((prevItem) => ({ ...prevItem, [name]: value }));
  };
  const addItem = () => {
    if (currentItem.title && currentItem.description) {
      setItems((prevItems) => [...prevItems, currentItem]);
      setCurrentItem({ title: "", description: "" });
    }
  };
  const handleOnSubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: title,
      description: description,
      benefits: JSON.stringify(Array),
      oneBhk: OneBhk,
      twoBhk: TwoBhk,
      threeBhk: ThreeBhk,
      fourBhk: FourBhk,
      fiveBhk: FiveBhk,
      villa: Villa,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_HAPS_MAIN_BASE_URL + "SubscriptionCards/create-card",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          toast.success("Subscription Cards Added Successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
      })
      .catch((error) => console.log("error", error));
    console.log("submiut");
  };
  const HandleOnAddCard = () => {
    const value = [...Array];
    value.push({
      title: "",
      description: "",
      fields: [{ subTitle: "", subDesc: "" }],
    });
    setArray(value);
  };
  const HandleOnRemoveCard = () => {
    const value = [...Array];
    value.splice(Array.length - 1);
    setArray(value);
  };

  const HandleOnAddFields = (index) => {
    const value = [...Array];
    value[index].fields.push({
      subTitle: "",
      subDesc: "",
    });
    setArray(value);
  };

  const HandleOnRemoveFields = (index, subindex) => {
    const value = [...Array];
    value[index].fields.splice(subindex, 1);
    setArray(value);
  };
  const HandleSetValue = (e, index) => {
    const { name, value } = e.target;
    const NewArray = [...Array];
    NewArray[index][name] = value;
    setArray(NewArray);
  };
  const HandleOnChangeFieldValue = (e, SubIndex, index) => {
    const { name, value } = e.target;
    const NewArray = [...Array];
    NewArray[index].fields[SubIndex][name] = value;
    setArray(NewArray);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster position="top-center" reverseOrder={false} />
      <Toaster position="top-center" reverseOrder={false} />

      <div className="border-solid border-2 black-indigo-600 mt-6">
        <div style={{ fontSize: 15 }} className="px-8 mt-5 font-bold">
          Add Subscription
        </div>
        <div>
          <div className="flex items-center">
            <div className="w-full px-4">
              <label className="text-gray-700 text-xs font-bold mb-2">Main Heading</label>
              <input
                required
                onChange={(e) => settitle(e.target.value)}
                name="title"
                className="appearance-none block w-full text-sm  text-gray-700 border border-blue-500 rounded px-1.5 py-1.5 leading-tight focus:outline-none "
                id="grid-first-name"
                value={title}
                type="text"
                placeholder="Add Title"
              />
            </div>
            <div className="w-full px-4">
              <label className="text-gray-700 text-xs font-bold mb-2">
                {" "}
                Main Heading Desciption
              </label>
              <input
                required
                name="description"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="Add description"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 justify-center mx-5">
          <div className="w-full px-4">
            <label className="text-gray-700 text-xs font-bold mb-2">1 Bhk</label>
            <input
              required
              onChange={(e) => setOneBhk(e.target.value)}
              name="title"
              className="appearance-none block w-full text-sm  text-gray-700 border border-blue-500 rounded px-1.5 py-1.5 leading-tight focus:outline-none "
              id="grid-first-name"
              value={OneBhk}
              type="text"
              placeholder="Enter 1 bhk Price"
            />
          </div>
          <div className="w-full px-4">
            <label className="text-gray-700 text-xs font-bold mb-2">2 Bhk</label>
            <input
              required
              name="description"
              value={TwoBhk}
              onChange={(e) => setTwoBhk(e.target.value)}
              className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Enter 2 bhk Price"
            />
          </div>
          <div className="w-full px-4">
            <label className="text-gray-700 text-xs font-bold mb-2">3 Bhk</label>
            <input
              required
              name="description"
              value={ThreeBhk}
              onChange={(e) => setThreeBhk(e.target.value)}
              className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Enter 3 bhk Price"
            />
          </div>
          <div className="w-full px-4">
            <label className="text-gray-700 text-xs font-bold mb-2">4 Bhk</label>
            <input
              required
              name="description"
              value={FourBhk}
              onChange={(e) => setFourBhk(e.target.value)}
              className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Enter 4 bhk Price"
            />
          </div>
          <div className="w-full px-4">
            <label className="text-gray-700 text-xs font-bold mb-2">5 Bhk</label>
            <input
              required
              name="description"
              value={FiveBhk}
              onChange={(e) => setFiveBhk(e.target.value)}
              className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Enter 5 bhk Price"
            />
          </div>
          <div className="w-full px-4">
            <label className="text-gray-700 text-xs font-bold mb-2">Villa</label>
            <input
              required
              name="description"
              value={Villa}
              onChange={(e) => setVilla(e.target.value)}
              className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Enter Villa Price"
            />
          </div>
        </div>
        <div className="">
          <div className="flex justify-between items-center mt-5">
            <span
              onClick={HandleOnAddCard}
              type="submit"
              className="m-2 cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold text-xs py-2 px-4 rounded"
            >
              Add
            </span>
            <span
              onClick={HandleOnRemoveCard}
              type="submit"
              className="m-2 cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold text-xs py-2 px-4 rounded"
            >
              Remove
            </span>
          </div>
          {Array.map((main, index) => {
            return (
              <div key={index} className="my-2">
                <div className="flex items-center">
                  <div className="w-full px-4">
                    <label className="text-gray-700 text-xs font-bold mb-2">Main Title</label>
                    <input
                      required
                      onChange={(e) => HandleSetValue(e, index)}
                      name="title"
                      className="appearance-none block w-full text-sm  text-gray-700 border border-blue-500 rounded px-1.5 py-1.5 leading-tight focus:outline-none "
                      id="grid-first-name"
                      value={main.title}
                      type="text"
                      placeholder="Add Title"
                    />
                  </div>
                  <div className="w-full px-4">
                    <label className="text-gray-700 text-xs font-bold mb-2"> Main Desciption</label>
                    <input
                      required
                      name="description"
                      value={main.description}
                      onChange={(e) => HandleSetValue(e, index)}
                      className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                      placeholder="Add description"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-3">
                  <span
                    onClick={() => HandleOnAddFields(index)}
                    type="submit"
                    className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold text-xs py-2 px-4 rounded float-right"
                  >
                    Add Field
                  </span>
                </div>
                {main.fields.map((el, ina) => {
                  return (
                    <div className="flex" key={ina}>
                      <div className="w-full px-4">
                        <label className="text-gray-700 text-xs font-bold mb-2"> Sub Title</label>
                        <input
                          // required
                          name="subTitle"
                          value={el.subTitle}
                          onChange={(e) => HandleOnChangeFieldValue(e, ina, index)}
                          className="block w-full text-sm text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          placeholder="Add title"
                        />
                      </div>
                      <div className="w-full px-4 ">
                        <label className="text-gray-700 text-xs font-bold mb-2">
                          Sub Description
                        </label>
                        <input
                          // required
                          name="subDesc"
                          value={el.subDesc}
                          onChange={(e) => HandleOnChangeFieldValue(e, ina, index)}
                          className="block w-full text-sm text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          placeholder="Add description"
                        />
                      </div>
                      <div
                        className="flex justify-center items-center mt-8 mr-3"
                        onClick={() => HandleOnRemoveFields(index, ina)}
                      >
                        <FaTrash className="text-red-600 text-2xl" />
                      </div>
                    </div>
                  );
                })}

                <hr />
              </div>
            );
          })}

          <div className="m-3 grid grid-cols-3 text-xs  mt-4">
            <div>
              <button
                onClick={handleOnSubmit}
                type="submit"
                className="m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddSubscription;
