import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ListSubscription() {
  let Navigate = useNavigate();
  const deletstyle = { color: "red" };
  const editstyle = { color: "green" };

  const [subscription, setsubscription] = useState([]);

  const getsubscriptionlist = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_HAPS_MAIN_BASE_URL + "SubscriptionCards/get-all-cards",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setsubscription(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const HandleUserDelete = (id) => {
    console.log("delete", id);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_HAPS_MAIN_BASE_URL + `SubscriptionCards/delete-card/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200) {
          getsubscriptionlist();
          toast.success("Card Deleted Successfully");
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getsubscriptionlist();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster position="top-center" reverseOrder={false} />

      <div className="border-solid border-2 black-indigo-600 mt-6">
        <div style={{ fontSize: 15 }} className="px-8 mt-5">
          List Subscription
        </div>
        <div>
          <table className="min-w-full text-left text-sm font-light">
            <thead className="border-b font-medium dark:border-neutral-500">
              <tr>
                <th scope="col" className="px-6 py-4">
                  Sr.No
                </th>
                <th scope="col" className="px-6 py-4">
                  Title
                </th>
                <th scope="col" className="px-6 py-4">
                  Description
                </th>
                <th scope="col" className="px-6 py-4">
                  Benefits
                </th>
                <th scope="col" className="px-6 py-4">
                  Customers
                </th>
                {/* <th scope="col" className="px-6 py-4">Created by</th> */}
                <th scope="col" className="px-2 py-2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {subscription &&
                subscription.map((el, i) => {
                  return (
                    <tr className="border-b dark:border-neutral-500" key={i}>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">{i + 1}</td>
                      <td className="whitespace-nowrap px-6 py-4">{el.title}</td>
                      <td className="whitespace-nowrap px-6 py-4">{el.description}</td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {" "}
                        <button onClick={() => alert(JSON.stringify(el))}>View</button>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 ml-3">
                        <button
                          onClick={() =>
                            Navigate({
                              pathname: "/Subscribers",
                              search: createSearchParams({
                                id: el.id,
                              }).toString(),
                            })
                          }
                        >
                          View
                        </button>
                      </td>
                      {/* {el.benefits.map((item, index) => {
                        return (
                        <td className="whitespace-nowrap px-6 py-4">{item.title}</td>
                        )
                      })} */}

                      <td className="grid grid-cols-8 mt-4">
                        <RiDeleteBin6Fill
                          style={deletstyle}
                          className="cursor-pointer"
                          onClick={() => HandleUserDelete(el.id)}
                        />
                        <MdModeEdit
                          style={editstyle}
                          className="cursor-pointer"
                          onClick={() => Navigate(`/EditSubscription/${el.id}`)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ListSubscription;
