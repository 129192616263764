import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import jsPDF from "jspdf";
import printJS from "print-js";
import { useRef } from "react";
import html2canvas from "html2canvas";

const QuotationForm = () => {
  const { id } = useParams();
  const [data, setdata] = useState();
  const divRef = useRef(null);
  console.log("ss", divRef);
  const getquotationform = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_HAPS_MAIN_BASE_URL + ` Quotation/GetQuotationById/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 200) {
          setdata(result.Data);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getquotationform();
  }, []);
  const numberToWords = require("number-to-words");

  // Assuming data.total_amount is a number
  const totalAmount = data?.total_amount ? data?.total_amount : 0;

  // Convert the number to its text representation
  const totalAmountInWords = numberToWords.toWords(totalAmount);

  //

  const exportPDF = async () => {
    const div = divRef.current;

    if (!div) {
      return; // No div to export
    }

    const pdf = new jsPDF("p", "mm", "a4");
    // pdf.addPage('a4', 'p');
    const pdfWidth = pdf.internal.pageSize.width;
    const maxPageHeight = pdf.internal.pageSize.height;
    // const maxPageHeight1 = pdf.internal.pageSize.getHeight;
    const maxPageHeightPixels = (maxPageHeight / 25.4) * 185;
    // const maxPageHeightPixels = maxPageHeight;
    // alert(`div${maxPageHeight}`);

    let yPosition = 0;
    let pageNumber = 1;

    const captureAndAddToPDF = async () => {
      const canvas = await html2canvas(div, {
        y: yPosition, // Capture content starting from the current yPosition
        height: maxPageHeightPixels, // Capture content up to the maximum page height
      });

      const imgData = canvas.toDataURL("image/png", 1);

      if (pageNumber > 1) {
        pdf.addPage(); // Add a new page for subsequent content
      }

      pdf.addImage(imgData, "PNG", 5, 5, pdfWidth, 0); // Use 0 for the height to auto-calculate

      // Calculate the remaining height after adding content
      // alert(`pageheight${maxPageHeightPixels}`);
      // alert(`canvas height${canvas.height}`);
      yPosition += canvas.height;

      // Check if there's more content below
      if (yPosition < div.clientHeight) {
        pageNumber++;
        captureAndAddToPDF(); // Recursively capture and add more pages
      } else {
        pdf.save("sample.pdf"); // Save the PDF when all content is added
      }
    };

    captureAndAddToPDF(); // Start the process
  };

  const listItems = [
    "Scope of the quotation is limited to above mentioned areas",
    "Any changes in the approved design will attract additional cost, quotation and longer delivery period",
    "Bathroom, terrace, passage, study room, balcony, open plot, dry balcony, wash basin are treated as different entities",
    "Design, painting, furnishing, electrical fit out/work, other fittings for above entities are not included in quotation unless requested specifically",
    "Any delay in selection process of electronic/electrical/furnishing/other fittings will proportionately delay the delivery timelines",
    "Warranty: Electrical fittings – 2 years, electronic/electrical gadgets (per manufacturer guarantee policy)",
    "Warranty: Furniture - Hardware – 3 years",
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <button onClick={exportPDF}>Export PDF</button>
        <div className="w-full border-4 border-black-600 mt-8" ref={divRef}>
          <div className=" border-4 border-black-600 mt-6 h-100 mr-60 ml-80">
            <div className="font-serif px-8 mt-2 font-semibold text-center font-medium  text-black mb-4 pt-2 pb-2">
              ARRANGE FREE
            </div>
            <hr className="border-1 border-black mx-8" />
            <div className="text-xs font-medium text-center ">
              Renovation | customized Furniture | Flooring | Modern Kitchen | Lightning And False
              Ceiling | Soft Furnishing
            </div>
            <hr className="border-1 border-black mx-8" />
          </div>

          <div className="ms-40 border-2 border-black-600 mt-8  h-26 mr-40 ml-70 ">
            <div className="mx-3 grid grid-cols-2 text-sm font-normal mt-2 ">
              Quotation No: #{data?.id}
              <div className="ml-40 text-center ">Date: {data?.created_at.slice(0, 10)} </div>
            </div>
            <div className="mx-3 grid grid-cols-2 text-sm font-normal mt-2">
              To: {data?.customer_name}
              <div className="ml-40 text-center ">Time: {data?.created_at.slice(10)} </div>
            </div>
            <div className="mx-3 grid grid-cols-2 text-sm font-normal mt-2">
              Addess: {data?.address}
            </div>
            <div className="mx-3 grid grid-cols-2 text-sm font-normal mt-2 mb-10">
              Phone No: {data?.phone}
            </div>
          </div>

          <div className="text-base mt-5 text-center font-medium text-black">
            {" "}
            <label> Subject:Your Requirement of Interior Services </label>
          </div>

          <div className="flex flex-col mr-60 ml-60 mt-6 font-semibold ">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <table className="min-w-full border text-center text-sm font-dark dark:border-neutral-500">
                  <thead className="border-b font-medium dark:border-neutral-500">
                    <tr>
                      <th
                        colSpan={6}
                        className="border-2 px-6 py-2 text-orange-600/75 ml-40 text-base dark:border-neutral-500"
                      >
                        Quotation-Interior Work with Material and Execution
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    <tr className="border-b dark:border-neutral-500">
                      <td className="whitespace-nowrap border-r px-6 py-4 font-medium dark:border-neutral-500">
                        Sr.No.
                      </td>
                      <td className="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                        Description
                      </td>
                      <td className="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                        Size
                      </td>
                      <td className="whitespace-nowrap border-r px-6 py-4">Quantity</td>
                      <td className="whitespace-nowrap border-r px-6 py-4">Rate</td>
                      <td className="whitespace-nowrap border-r px-6 py-4">Amount</td>
                    </tr>
                    {data?.items
                      ? JSON.parse(data.items).map((item, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <th
                                style={{ fontSize: 14 }}
                                colSpan={6}
                                className="border-2 px-6 py-2 text-black ml-40"
                              >
                                {item.title}
                              </th>
                            </tr>
                            {item.subfiled.map((subfield, subIndex) => (
                              <tr key={subIndex} className="border-b dark:border-neutral-500">
                                <td className="whitespace-nowrap border-r px-6 py-4 font-medium dark:border-neutral-500">
                                  {data?.id}
                                </td>
                                <td className="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                                  {subfield.description}
                                </td>
                                {/* <td className="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500"></td> */}
                                <td className="whitespace-nowrap border-r px-6 py-4">
                                  {subfield.size}
                                </td>
                                <td className="whitespace-nowrap border-r px-6 py-4">
                                  {subfield.quantity}
                                </td>
                                <td className="whitespace-nowrap border-r px-6 py-4">
                                  {subfield.rate}
                                </td>
                                <td className="whitespace-nowrap border-r px-6 py-4">
                                  {subfield.amount}
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))
                      : null}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={6} className="border-2 px-6 py-2 dark:border-neutral-500">
                        <div className="text-left">
                          Amount :{" "}
                          <span className=" text-orange-600/75  text-base">
                            {totalAmountInWords}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div className="text-base font-medium mt-10 text-left ml-60 text-black">
            <label> Payment Slab: </label>
          </div>

          <div className="flex flex-col mr-60 ml-60 mt-4 font-semibold ">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <table className="min-w-full border text-center text-sm font-dark border-black">
                  <thead className="border-b font-medium border-black">
                    <tr>
                      <th className="border-2 px-6 py-2 text-black-600/75 ml-40 text-sm border-black">
                        Payment Slab{" "}
                      </th>
                      {/* <th className="border-2 px-6 py-2 text-black-600/75 ml-40 text-sm border-black">
                                            % </th> */}
                      <th className="border-2 px-6 py-2 text-black-600/75 ml-40 text-sm border-black">
                        amount
                      </th>
                      <th className="border-2 px-6 py-2 text-black-600/75 ml-40 text-sm border-black">
                        Date{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    <tr className="border-b border-black">
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        1st Installment
                      </td>
                      {/* <td className="whitespace-nowrap border-r px-6 py-4">
                                            20% </td> */}
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Firstinstallmentamount
                          : ""}
                      </td>
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Firstinstallmentdate
                          : ""}
                      </td>
                    </tr>

                    <tr className="border-b border-black">
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        2nd Installment
                      </td>
                      {/* <td className="whitespace-nowrap border-r px-6 py-4">
                                            20% </td> */}
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Secondinstallmentamount
                          : ""}
                      </td>
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Secondinstallmentdate
                          : ""}
                      </td>
                    </tr>

                    <tr className="border-b border-black">
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        3rd Installment
                      </td>
                      {/* <td className="whitespace-nowrap border-r px-6 py-4">
                                            20% </td> */}
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Thirdinstallmentamount
                          : ""}
                      </td>
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Thirdinstallmentdate
                          : ""}
                      </td>
                    </tr>

                    <tr className="border-b border-black">
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        4th Installment
                      </td>
                      {/* <td className="whitespace-nowrap border-r px-6 py-4">
                                            20% </td> */}
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Fourthinstallmentamount
                          : ""}
                      </td>
                      <td className="whitespace-nowrap border-r px-6 py-4 border-black">
                        {data?.installment
                          ? JSON.parse(data?.installment).Fourthinstallmentdate
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="text-base font-medium mt-10 text-left ml-60 text-black">
            <label> Material Status: </label>
          </div>

          <div className="flex flex-col mr-60 ml-60 mt-4 font-semibold">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <table className="min-w-full mt-5 text-center text-base font-dark dark:border-neutral-500">
                  <tbody className="text-sm">
                    {data?.mark_list
                      ? JSON.parse(data.mark_list).map((category, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              {/* <br></br> */}
                              <th
                                colSpan={10}
                                className="py-2 text-black text-base ml-5 text-center mt-15 "
                              >
                                {category.title}
                              </th>
                            </tr>

                            {category?.items.map((item, itemIndex) => (
                              <tr key={itemIndex}>
                                <td className="border-2 px-6 py-2 text-black-600/75  text-sm dark:border-neutral-500">
                                  {item?.title}
                                </td>
                                <td className="border-2 px-6 py-2 text-black-600/75 ml-40 text-sm dark:border-neutral-500">
                                  {item.checked === 1 ? "Mark" : "--"}
                                </td>
                                {/* Adjust the number of columns as needed */}
                              </tr>
                            ))}
                          </React.Fragment>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="text-base font-medium mt-10 text-left ml-60 text-black">
            <label> Time-Line: </label>
          </div>

          <div className="flex flex-1 ml-40 mt-10">
            <p className="pr-6 mt-4 font-medium text-sm">Civil Work</p>
            <div className="-ml-24 mt-14 font-medium text-sm mr-5">Electrical Work</div>
            <div className="-ml-28 mt-24 font-medium text-sm mr-3">False Ceiling</div>
            <div className="-ml-20 mt-36 font-medium text-sm mr-16">Tilling</div>
            <div className="-ml-32 mt-48 text-center font-medium text-sm mr-5">Electrical Work</div>
            <div className="static ...">
              <div className=" inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-700 opacity-100 dark:opacity-50 absolute bottom-15 left-68 ">
                <div className="border-solid border-2 border-orange-500 bg-orange-500 ...  w-32 h-8 mt-2 text-right text-white text-sm">
                  3-days
                </div>
              </div>
            </div>

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="static ...">
              <div className=" inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-100 opacity-100 dark:opacity-50 absolute bottom-15 left-68 ">
                <div className="border-solid border-2 border-orange-500 bg-orange-500 ...  w-20 h-8 mt-14 text-right text-white text-sm">
                  {" "}
                  2-days
                </div>
              </div>
            </div>

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />
            <div className="static ...">
              <div className=" inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-100 opacity-100 dark:opacity-50 absolute bottom-15 left-68 ">
                <div className="border-solid border-2 border-orange-500 bg-orange-500 ...  w-20 h-8 mt-24 text-right text-white text-sm">
                  {" "}
                  2-days
                </div>
              </div>
            </div>

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="static ...">
              <div className=" inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-100 opacity-100 dark:opacity-50 absolute bottom-15 left-68 ">
                <div className="border-solid border-2 border-orange-500 bg-orange-500 ...  w-64 h-8 mt-36 text-right text-white text-sm">
                  {" "}
                  7-days
                </div>
              </div>
            </div>

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="static ...">
              <div className=" inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-100 opacity-100 dark:opacity-50 absolute bottom-15 left-68 ">
                <div className="border-solid border-2 border-orange-500 bg-orange-500 ...  w-10 h-8 mt-48 text-right text-white text-sm">
                  {" "}
                  1-day
                </div>
              </div>
            </div>

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />

            <div className="inline-block ml-10  h-[250px] min-h-[1em] w-0.5 self-stretch bg-gray-300 opacity-100 dark:opacity-50" />
          </div>

          <div className="border-2 border-black-600 mt-6 bg-orange-100 h-20 flex justify-center items-center">
            {/* <div className=" flex items-center justify-center mx-3 text-lg font-normal ml-96 h-14 mt-2 mr-96"> */}
            Total Days-15
            {/* </div> */}
          </div>
          <div className="ml-40 mt-10 text-sm font-bold"> Thanks </div>
          <div className="ml-40 mt-2 text-sm font-bold"> Vaishnavi Hodkar </div>
          <div className="ml-40 mt-2 text-sm font-bold"> Arrange Free </div>
          <div className="ml-40 mt-2 text-sm font-normal">
            Mob:<span className="text-xs text-blue-600/100">9371885000</span>
          </div>
          <div className="ml-40 mt-2 text-sm font-normal">
            <a href="https://arrangefree.com/" target="_blank" rel="noopener noreferrer">
              Website: <span className="text-xs text-blue-600/100">www.arrangefree.com</span>
            </a>
          </div>

          <div className="text-base font-medium mt-10 text-left ml-40 text-black">
            <label> Terms and Conditions </label>
          </div>
          <div className="flex flex-col ml-40 ">
            {/* <ul className="mt-2"> */}
            {listItems.map((item, index) => (
              <li className=" mt-0.5 text-sm font-bold text-black" key={index}>
                {item}
              </li>
            ))}
            {/* </ul> */}
            <div>
              <p className="text-black text-sm font-medium mt-10">
                724, 7th Floor, Clover Hills Plaza, NIBM Road, Mohammadwadi, Pune Maharashtra –
                411048 Phone: 9371885000 Email Id:support@arrangefree
              </p>
              <a
                href="https://arrangefree.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-blue-600/100"
              >
                www.arrangefree.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default QuotationForm;
