import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AiFillCloseCircle } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Toaster, toast } from "react-hot-toast";

const CustomerEditQuotation = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const deletstyle = { color: "red", fontSize: "1.5rem" };

  const [Data, setData] = useState({});
  const [isTextBoxOpen, setIsTextBoxOpen] = useState(true);
  const [isTextBox, setIsTextBox] = useState(false);
  const [isTimeline, setIsTimeline] = useState(true);
  const [markListNames, setMarkListNames] = useState([]);
  const [titleinputFields, setTitleInputFields] = useState([]);
  const [otherFormData, setOtherFormData] = useState({});
  const [installMent, setInstallMent] = useState([]);
  const [componantLoader, setComponantLoader] = useState(false);
  // const [, setTimeline]
  const { id } = useParams();
  const [Loader, setLoader] = useState(false);
  const [CustomerData, setCustomerData] = useState({
    ["address"]: "",
    ["customer_name"]: "",
    ["phone"]: "",
    ["items"]: [],
    ["created_by"]: "",
    ["cgst"]: "",
    ["sgst"]: "",
    ["id"]: "",
    ["total_amount"]: "",
    ["installment"]: [],
    ["mark_list"]: [],
    ["time_line"]: [],
  });

  useEffect(() => {
    setLoader(true);
    ApiFetch();
  }, []);

  const ApiFetch = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_HAPS_MAIN_BASE_URL + `Quotation/GetQuotationById/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("res", result);
        if (result.Status === 200) {
          setCustomerData({
            ...CustomerData,
            ["address"]: result.Data.address,
            ["customer_name"]: result.Data.customer_name,
            ["phone"]: result.Data.phone,
            ["items"]: JSON.parse(result.Data.items),
            ["created_by"]: result.Data.created_by,
            ["cgst"]: result.Data.cgst,
            ["sgst"]: result.Data.sgst,
            ["id"]: result.Data.id,
            ["total_amount"]: result.Data.total_amount,
            ["installment"]: JSON.parse(result.Data.installment),
            ["mark_list"]: JSON.parse(result.Data.mark_list),
            ["time_line"]: JSON.parse(result.Data.time_line),
          });
          setLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleOnUpdate = (e) => {
    setLoader(true);
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: CustomerData.id,
      customer_name: CustomerData.customer_name,
      phone: CustomerData.phone,
      address: CustomerData.address,
      items: JSON.stringify(CustomerData.items),
      mark_list: JSON.stringify(CustomerData.mark_list),
      total_amount: CustomerData.total_amount,
      sgst: CustomerData.sgst,
      cgst: CustomerData.cgst,
      installment: JSON.stringify(CustomerData.installment),
      time_line: JSON.stringify(CustomerData.time_line),
      created_by: CustomerData.created_by,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_HAPS_MAIN_BASE_URL + "Quotation/Update", requestOptions)
      .then((response) => response.json())

      .then((result) => {
        if (result.Status === 200) {
          setLoader(false);
          toast.success("Quotation Updated Successfully");

          setTimeout(() => {
            navigate("/listquotation");
          }, 500);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const navigate = useNavigate();
  const BackToListQuotation = () => {
    navigate("/listquotation");
  };

  const RedirectToQuotationform = () => {
    navigate("/quotationform");
  };

  const handleButtonClick = async () => {
    setIsTextBoxOpen(true);
    setComponantLoader(1);
    CustomerData.items.push({
      title: "",
      subfiled: [],
    });

    setTimeout(() => {
      setComponantLoader(false);
    }, 500);
  };

  const removeTitleFields = (index) => {
    setComponantLoader(1);
    const rows = CustomerData.items;
    rows.splice(index, 1);
    setTimeout(() => {
      setComponantLoader(false);
    }, 500);
  };

  //const [inputFields, setInputFields] = useState([]);
  //const [timeline, setTimeline] = useState([]);

  const addInputField = (i) => {
    setComponantLoader(1);
    setIsTextBox(true);
    const values = CustomerData.items;
    values[i].subfiled.push({
      description: "",
      size: "",
      quantity: "",
      rate: "",
      amount: "",
    });
    setTimeout(() => {
      setComponantLoader(false);
    }, 500);
  };
  console.log("CustomerData.items", CustomerData.items);

  const removeInputFields = (index, i) => {
    const updatedCustomerData = { ...CustomerData };

    const updatedItems = [...updatedCustomerData.items];

    const updatedSubfield = [...updatedItems[index].subfiled];

    updatedSubfield.splice(i, 1);

    updatedItems[index].subfiled = updatedSubfield;

    updatedCustomerData.items = updatedItems;

    setCustomerData(updatedCustomerData);
  };

  const handleMainFieldChange = (index, evnt) => {
    const { name, value } = evnt.target;

    const list = CustomerData.items;

    list[index][name] = value;
    // setTitleInputFields([...list]);
  };

  const handleChange = (index, i, evnt) => {
    const { name, value } = evnt.target;
    const list = CustomerData.items;

    list[index].subfiled[i][name] = value;
    // setTitleInputFields([...list]);
  };

  const addMoreInputField = async () => {
    setComponantLoader(1);

    await CustomerData.time_line.push({
      task: "",
      days: "",
    });
    setComponantLoader(false);
  };

  const removeTimeline = (index) => {
    setComponantLoader(1);
    const rows = CustomerData.time_line;
    rows.splice(index, 1);
    setTimeout(() => {
      setComponantLoader(false);
    }, 500);
  };

  const TimelineChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = CustomerData.time_line;
    list[index][name] = value;

    //setTimeline(list);
  };

  console.log("Time_line", CustomerData.time_line);

  const options = ["Sqft", "Pcs", "Mcs"];
  const onOptionChangeHandler = (event) => {
    console.log("User Selected Value - ", event.target.value);
  };
  const handleOnchangeInstallment = (i, targetvalue) => {
    const { name, value } = targetvalue.target;
    setInstallMent({ ...installMent, [name]: value });
  };

  const [MarkListValueAndData, setMarkListValueAndData] = useState(CustomerData.mark_list);

  const handleChangeCheck = (outerIndex, innerIndex, newValue) => {
    const newData = [...CustomerData.mark_list];
    newData[outerIndex].items[innerIndex].checked = newValue;
    // setMarkListValueAndData(newData);
  };
  console.log("CustomerData.mark_list", CustomerData.mark_list);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {console.log("uodate ",CustomerData)}
      <Toaster position="top-center" reverseOrder={false} />
      <div className="border-solid border-2 black-indigo-600 mt-6">
        <div style={{ fontSize: 15 }} className="px-8 mt-5">
          Add Quotation
        </div>
        <div className="px-8 mt-6">Customer Details</div>
        <div className="mt-6">
          <form className="w-full" onSubmit={handleOnUpdate}>
            <div className="flex ">
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">Customer Name</label>
                <input
                  defaultValue={CustomerData.customer_name}
                  onChange={(e) =>
                    setCustomerData({ ...CustomerData, ["customer_name"]: e.target.value })
                  }
                  className="appearance-none block w-full text-sm  text-gray-700 border border-blue-500 rounded px-1.5 py-1.5 leading-tight focus:outline-none "
                  id="grid-first-name"
                  type="text"
                />
              </div>
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">Phone No</label>
                <input
                  defaultValue={CustomerData.phone}
                  onChange={(e) =>
                    setCustomerData({ ...CustomerData, ["phone"]: e.target.value })
                  }
                  className="block w-full text-sm  text-gray-700 border border-gray-200 rounded px-1.5 py-1.5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Phone No"
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-full px-4">
                <label className="text-gray-700 text-xs font-bold mb-2">Address</label>
                <textarea
                  defaultValue={CustomerData.address}
                  onChange={(e) =>
                    setCustomerData({ ...CustomerData, ["address"]: e.target.value })
                  }
                  className="appearance-none block w-full  text-sm  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-password"
                  type="password"
                  placeholder="Address"
                />
              </div>
            </div>

            <div className=" m-4 border-s-4 border-2 border-black-400 ">
              <div className="flex flex-wrap -mx-3 mb-5 px-4">
                <div className="w-full px-4 ">
                  <label className="text-gray-700 text-xs font-bold mb-2">Items</label>
                  <div className="grid grid-cols-8 text-xs font-bold mt-2">
                    <div>Sr.no</div>
                    <div>Description</div>
                    <div>Size</div>
                    <div>Quantity</div>
                    <div>Type</div>
                    <div>Rate</div>
                    <div>Amount</div>
                    <div>Action</div>
                  </div>
                  <hr />

                  <div>
                    {isTextBoxOpen && (
                      <div className="mt-4">
                        {componantLoader == 1 ? (
                          <div className="flex justify-center items-center h-[75vh] w-full">
                            <Spin indicator={antIcon} />
                          </div>
                        ) : (
                          CustomerData.items.map((data, index) => {
                            const { title } = data;
                            return (
                              <div key={index}>
                                <div className="flex" key={index}>
                                  <div className="w-full px-0 mt-4">
                                    <input
                                      type="text"
                                      defaultValue={title}
                                      onChange={(e) => handleMainFieldChange(index, e)}
                                      name="title"
                                      className=" text-gray-700 border border-gray-200 rounded py-3 px-48 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                    />

                                    <button
                                      type="button"
                                      className="ml-16 text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
                                      onClick={() => addInputField(index)}
                                    >
                                      Add Row
                                    </button>

                                    <button
                                      className="ml-96"
                                      type="button"
                                      onClick={() => removeTitleFields(index)}
                                    >
                                      <AiFillCloseCircle style={deletstyle} />
                                    </button>
                                  </div>
                                </div>
                                <hr />
                                <div>
                                  {data.subfiled.map((el, i) => {
                                    const { description, size, quantity, rate, amount } = el;
                                    return (
                                      <div className="grid grid-cols-8 gap-4 mt-4" key={i}>
                                        <div className="w-full"> {i + 1} </div>
                                        <div>
                                          <input
                                            type="text"
                                            onChange={(evnt) => handleChange(index, i, evnt)}
                                            defaultValue={description}
                                            name="description"
                                            placeholder="Description"
                                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                            id="grid-text"
                                          />
                                        </div>

                                        <div>
                                          <input
                                            type="text"
                                            onChange={(evnt) => handleChange(index, i, evnt)}
                                            defaultValue={size}
                                            name="size"
                                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                            id="grid-text"
                                          />
                                        </div>

                                        <div>
                                          <input
                                            type="text"
                                            onChange={(evnt) => handleChange(index, i, evnt)}
                                            defaultValue={quantity}
                                            name="quantity"
                                            placeholder="0"
                                            disabled
                                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                            id="grid-text"
                                          />
                                        </div>

                                        <div>
                                          {" "}
                                          <select
                                            className="appearance-none block w-1/2 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                            id="grid-text"
                                            onChange={onOptionChangeHandler}
                                          >
                                            <option>select</option>
                                            {options.map((option, index) => {
                                              return <option key={index}>{option}</option>;
                                            })}
                                          </select>
                                        </div>
                                        <div>
                                          <input
                                            type="text"
                                            onChange={(evnt) => handleChange(index, i, evnt)}
                                            defaultValue={rate}
                                            name="rate"
                                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                            id="grid-text"
                                          />
                                        </div>

                                        <div>
                                          <input
                                            type="text"
                                            onChange={(evnt) => handleChange(index, i, evnt)}
                                            defaultValue={amount}
                                            name="amount"
                                            placeholder="0"
                                            className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
                                            id="grid-text"
                                          />
                                        </div>

                                        <div>
                                          <div className="rounded-t-lg text-xs font-bold rounded-full">
                                            <button
                                              type="button"
                                              className="grid grid-cols-8 mt-4"
                                              onClick={() => removeInputFields(index, i)}
                                            >
                                              <AiFillCloseCircle style={deletstyle} />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <hr />
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}

                    <hr />
                  </div>
                  <div className="rounded-t-lg text-xs mt-4 font-bold rounded-full">
                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleButtonClick()}
                    >
                      Add Title
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className=" m-4 border-s-4 border-2 border-black-400 ">
              <div className="flex flex-wrap -mx-3 mb-5 px-4">
                <div className="w-full px-4 ">
                  <label className="text-gray-700 text-xs font-bold mb-2">Mark List</label>

                  {CustomerData.mark_list.map((ma, index) => {
                    return (
                      <>
                        <div className="grid grid-cols-2  text-xs font-bold mt-4" key={index}>
                          <div>{ma.title}</div>
                          <div className="text-center">Mark</div>
                        </div>
                        <hr />
                        {ma.items.map((da, io) => {
                          return (
                            <div className="grid grid-cols-2  text-xs  mt-4" key={io}>
                              <div>{da.title}</div>
                              <div className="text-center">
                                <input
                                  id="vue-checkbox"
                                  type="checkbox"
                                  value={da.title}
                                  onChange={(e) => handleChangeCheck(index, io, 1)}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                  defaultChecked={da.checked === 1 ? true : false}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}

                  <div className="border-s-4 border-2 border-black-400 mt-6">
                    <div className="flex flex-wrap -mx-3 mb-5 px-4">
                      <div className="w-full px-4 ">
                        <label className="text-gray-700 text-xs font-bold mb-2">Total</label>
                        <div className="grid grid-cols-6 text-xs  mt-4">
                          <div>Total</div>
                          <div className="text-left">
                            <input
                              type="text"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block p-2.5"
                              defaultValue={CustomerData.total_amount}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-6 text-xs  mt-4">
                          <div>SGST(9%)</div>
                          <div className="text-left">
                            <input
                              onChange={(e) => (CustomerData.sgst = e.target.value)}
                              defaultValue={CustomerData.sgst}
                              type="text"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block  p-2.5"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-6 text-xs  mt-4">
                          <div>CGST(9%)</div>
                          <div className="text-left">
                            <input
                              onChange={(e) => (CustomerData.cgst = e.target.value)}
                              type="text"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block p-2.5"
                              defaultValue={CustomerData.cgst}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-6 text-xs  mt-4">
                          <div>Total Amount</div>
                          <div className="text-left">
                            <input
                              onChange={(e) => (CustomerData.total_amount = e.target.value)}
                              type="text"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block  p-2.5"
                              defaultValue={CustomerData.total_amount}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-s-4 border-2 border-black-400 mt-6">
                    <div className="flex flex-wrap -mx-3 mb-5 px-4">
                      <div className="w-full px-4 ">
                        <label className="text-gray-700 text-xs font-bold mb-2">Installment</label>
                        <div className="grid grid-cols-4  text-xs  mt-4">
                          <div>Total</div>
                          <div>%</div>
                          <div>Amount</div>
                          <div>Date</div>
                        </div>
                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>1st Installment</div>
                          <div>20%</div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Firstinstallmentamount = e.target.value)
                              }
                              type="text"
                              name="Firstinstallmentamount"
                              className="bg-gray-50 border w-48 h-10 disabled border-gray-300 rounded-lg block  p-2.5"
                              defaultValue={CustomerData.installment.Firstinstallmentamount}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Firstinstallmentdate = e.target.value)
                              }
                              type="date"
                              name="Firstinstallmentdate"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block  p-2.5"
                              defaultValue={CustomerData.installment.Firstinstallmentdate}
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>2nd Installment</div>
                          <div>20%</div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Secondinstallmentamount = e.target.value)
                              }
                              type="text"
                              name="Secondinstallmentamount"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block  p-2.5"
                              defaultValue={CustomerData.installment.Secondinstallmentamount}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Secondinstallmentdate = e.target.value)
                              }
                              type="date"
                              name="Secondinstallmentdate"
                              className="bg-gray-50 border w-48 h-10  border-gray-300 rounded-lg block p-2.5"
                              defaultValue={CustomerData.installment.Secondinstallmentdate}
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>3rd Installment</div>
                          <div>20%</div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Thirdinstallmentamount = e.target.value)
                              }
                              type="text"
                              name="Thirdinstallmentamount"
                              className="bg-gray-50 border w-48 h-10 border-gray-300 rounded-lg block p-2.5"
                              defaultValue={CustomerData.installment.Thirdinstallmentamount}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Thirdinstallmentdate = e.target.value)
                              }
                              type="date"
                              name="Thirdinstallmentdate"
                              className="bg-gray-50 border w-48 h-10  border-gray-300 rounded-lg block p-2.5"
                              defaultValue={CustomerData.installment.Thirdinstallmentdate}
                            />
                          </div>
                        </div>
                        <hr />

                        <div className="grid grid-cols-4 text-xs  mt-4">
                          <div>4th Installment</div>
                          <div>20%</div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Fourthinstallmentamount = e.target.value)
                              }
                              type="text"
                              name="Fourthinstallmentamount"
                              className="bg-gray-50 border w-48 h-10  border-gray-300 rounded-lg block p-2.5"
                              defaultValue={CustomerData.installment.Fourthinstallmentamount}
                            />
                          </div>
                          <div>
                            <input
                              required
                              onChange={(e) =>
                                (CustomerData.installment.Fourthinstallmentdate = e.target.value)
                              }
                              type="date"
                              name="Fourthinstallmentdate"
                              className="bg-gray-50 border w-48 h-10  border-gray-300 rounded-lg block p-2.5"
                              defaultValue={CustomerData.installment.Fourthinstallmentdate}
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>

                  <div className="border-s-4 border-2 border-black-400 mt-6">
                    <div className="flex flex-wrap -mx-3 mb-5 px-4">
                      <div className="w-full px-4 ">
                        <label className="text-gray-700 text-xs font-bold mb-2">Timeline</label>
                        <div className="grid grid-cols-3  text-xs  mt-4">
                          <div>Sr.No</div>
                          <div>Task</div>
                          <div>Days</div>
                        </div>
                        <hr />

                        <div>
                          {isTimeline && (
                            <>
                              {componantLoader == 1 ? (
                                <div className="flex justify-center items-center h-[75vh] w-full">
                                  <Spin indicator={antIcon} />
                                </div>
                              ) : (
                                CustomerData.time_line.map((data, index) => {
                                  const { task, days } = data;
                                  return (
                                    <div className="grid grid-cols-4 text-xs mt-4" key={index}>
                                      <div className="w-1/2"> {index + 1} </div>
                                      <div>
                                        <input
                                          type="text"
                                          onChange={(evnt) => TimelineChange(index, evnt)}
                                          name="task"
                                          defaultValue={task}
                                          className="bg-gray-50 border w-72 h-10  border-gray-300 rounded-lg block  p-2.5"
                                        />{" "}
                                      </div>

                                      <div className="ml-16">
                                        <input
                                          type="text"
                                          onChange={(evnt) => TimelineChange(index, evnt)}
                                          name="days"
                                          defaultValue={days}
                                          className="bg-gray-50 border w-72 h-10  border-gray-300 rounded-lg block  p-2.5"
                                        />
                                      </div>

                                      <div>
                                        <div className="ml-48">
                                          <button
                                            type="button"
                                            className="grid grid-cols-2 mt-4"
                                            onClick={() => removeTimeline()}
                                          >
                                            <AiFillCloseCircle style={deletstyle} />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </>
                          )}{" "}
                          <hr />
                        </div>

                        <hr />

                        <div className="grid grid-cols-6 text-xs  mt-4">
                          <div className="rounded-t-lg text-xs font-bold rounded-full">
                            <button
                              type="button"
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                              onClick={() => addMoreInputField()}
                            >
                              Add More
                            </button>
                          </div>

                          <div>Total Days </div>
                          <div>
                            <input
                              type="text"
                              className="bg-gray-50 border w-72 h-10 disabled border-gray-300 rounded-lg block p-2.5"
                              placeholder="0"
                            />
                          </div>
                        </div>

                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="m-3 grid grid-cols-3 text-xs  mt-4">
                <div>
                  <button
                    type="button"
                    className=" m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={BackToListQuotation}
                  >
                    Back
                  </button>

                  <button
                    className="m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    type="submit"
                  >
                    Update
                  </button>

                  <button
                    type="button "
                    className=" m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={RedirectToQuotationform}
                  >
                    Make copy
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CustomerEditQuotation;
